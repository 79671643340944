import React from "react"
import PropTypes from "prop-types"

const Tag = ({ children, isLastItem }) => (
  <p
    className={`inline-block text-sm text-neutral-600 rounded bg-neutral-200 px-3 py-2 my-2 ${
      isLastItem ? "mr-0" : "mr-3.5"
    }`}
  >
    {children}
  </p>
)

Tag.propTypes = {
  children: PropTypes.any,
  isLastItem: PropTypes.bool,
}

export default Tag

import React from "react"
import PropTypes from "prop-types"
import { UserIcon, ClockIcon } from "@heroicons/react/solid"
import Button from "../Button"
import Link from "../Link"
import Tag from "../Tag/Tag"
import { useModalUpdate } from "../../contexts/ModalContext"

const CourseCard = ({
  title,
  description,
  topic,
  level,
  category,
  slug,
  hours,
  numberOfPeople,
  hasButton = true,
  isLast = false,
  isCreateNewPage = false,
  isLastItem,
}) => {
  const tags = [topic[0], level[0], category[0]]
  const tagList = tags.map((tag, idx) => (
    <Tag isLastItem={idx === tags.length - 1} key={`key-${idx}`}>
      {tag.name}
    </Tag>
  ))

  const setIsModal = useModalUpdate()

  return hasButton ? (
    <div className="border border-neutral-400 w-full ease-in-out duration-300 hover:border-neutral-600">
      <div className="p-5 sm:p-6">
        <p className="text-2xl !leading-normal font-bold text-neutral-800 pb-4 border-b border-neutral-400 sm:text-3xl">
          {title}
        </p>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex flex-col lg:mr-2">
            <div className="mt-4">{tagList}</div>
            <p className="line-clamp-3 leading-normal text-neutral-600 my-3 lg:max-w-md">
              {description}
            </p>
            <div className="flex">
              <div className="flex justify-center items-center pr-4">
                <ClockIcon className="text-neutral-300 w-5" />
                <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
                  {hours}小時
                </p>
              </div>
              <div className="flex justify-center items-center">
                <UserIcon className="text-neutral-300 w-5" />
                <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
                  {numberOfPeople}人
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 lg:flex lg:flex-col">
            <Button
              isLink={false}
              onClick={setIsModal}
              size="xs"
              style="mr-4 sm:mb-2 lg:mr-0 lg:mb-4"
            >
              立即諮詢
            </Button>
            <Button to={`/course/${slug}`} size="xs" color="white">
              了解更多
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Link
      to={`/course/${slug}`}
      as={isCreateNewPage ? "a" : "link"}
      style={`border border-neutral-400 w-full p-5 ease-in-out duration-300 hover:border-neutral-600 sm:p-6 ${
        isLast ? "hidden sm:block lg:hidden" : ""
      } ${isLastItem ? "hidden lg:block" : ""}`}
    >
      <p className="text-2xl !leading-normal font-bold text-neutral-800 pb-4 border-b border-neutral-400 sm:text-3xl">
        {title}
      </p>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-col">
          <div className="mt-2">{tagList}</div>
          <p className="line-clamp-3 leading-normal text-neutral-600 mt-1 mb-3">
            {description}
          </p>
          <div className="flex">
            <div className="flex justify-center items-center pr-4">
              <ClockIcon className="text-neutral-300 w-5" />
              <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
                {hours}小時
              </p>
            </div>
            <div className="flex justify-center items-center">
              <UserIcon className="text-neutral-300 w-5" />
              <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
                {numberOfPeople}人
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

CourseCard.propTypes = {
  title: PropTypes.string,
  topic: PropTypes.array,
  level: PropTypes.array,
  category: PropTypes.array,
  description: PropTypes.string,
  slug: PropTypes.string,
  hours: PropTypes.number,
  numberOfPeople: PropTypes.number,
  hasButton: PropTypes.bool,
  isLast: PropTypes.bool,
  isCreateNewPage: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

export default CourseCard

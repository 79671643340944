import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import { ChevronRightIcon } from "@heroicons/react/solid"

const ContinueReading = ({ postSlug }) => {
  return (
    <Link to={postSlug}>
      <button type="button" className="inline-flex items-center group">
        <p className="text-lg text-amber-400 group-hover:text-amber-500 transition ease-in-out duration-300">
          繼續閱讀
        </p>
        <ChevronRightIcon
          className="h-6 text-amber-400 ease-in-out duration-300 group-hover:text-amber-500 sm:h-8"
          aria-hidden="true"
        />
      </button>
    </Link>
  )
}

ContinueReading.propTypes = {
  postSlug: PropTypes.string,
}

export default ContinueReading

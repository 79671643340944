import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import lunr from "lunr"
import { graphql } from "gatsby"
import PostCard from "../components/Card/PostCard"
import { formatDate } from "../functions/formatDate"
import CourseCard from "../components/Card/CourseCard"
import TeacherCard from "../components/Card/TeacherCard"
import OnlineCourseCard from "../components/Card/OnlineCourseCard"
import { ChevronRightIcon } from "@heroicons/react/solid"
import Consult from "../components/Consult"
import { useLandingPageData } from "../data/useLandingPageData"
import useHover from "../hooks/useHover"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Code } from "react-content-loader"
import { StaticImage } from "gatsby-plugin-image"

require("lunr-languages/lunr.stemmer.support.js")(lunr)
require("lunr-languages/lunr.zh.js")(lunr)
require("lunr-languages/lunr.multi.js")(lunr)

const NoResult = () => (
  <div className="flex flex-col items-center">
    <div className="h-48 sm:h-full mb-6">
      <StaticImage
        src="../images/img_NoResultsFound.png"
        alt="img_言果_qrcode"
        className="h-48 w-48 sm:h-72 sm:w-72"
        quality={100}
      />
    </div>
    <div className="text-lg leading-normal text-center font-bold text-neutral-800 sm:flex sm:text-2xl sm:leading-loose sm:text-neutral-900">
      <p>Oops！</p>
      <p>沒有相關的結果，請重新搜尋！</p>
    </div>
  </div>
)

const Navigation = ({ url, text, handleFunc }) => (
  <AnchorLink to={`${url}#anchor_link`} rel="next" stripHash className="block">
    <button
      className="flex justify-start items-center flex-nowrap group"
      onClick={handleFunc}
    >
      <p className="ease-in-out duration-300 text-lg text-neutral-800 group-hover:text-amber-500">
        {text}
      </p>
      <ChevronRightIcon className="ease-in-out duration-300 h-5 text-yellow-500 group-hover:text-amber-500 sm:h-8" />
    </button>
  </AnchorLink>
)

const FilterButton = ({
  name,
  value,
  count,
  isCurrentButton,
  setFilterButtonName,
  setShowAllCard,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <button
      value={value}
      ref={hoverRef}
      onClick={e => {
        setFilterButtonName(e.currentTarget.value)
        if (value !== "all") {
          setShowAllCard(true)
        } else {
          setShowAllCard(false)
        }
      }}
      className="relative"
      type="button"
    >
      <p className="leading-normal text-neutral-800 sm:text-xl">
        {name} {count}
      </p>
      <div
        className={`absolute left-0 top-7 w-full sm:top-8 ${
          isHovered || isCurrentButton
            ? "border-t-4 border-amber-400"
            : "border-transparent"
        }`}
      />
    </button>
  )
}

const Search = ({ data }) => {
  const { consultTitle, consultGatsbyImageData } = useLandingPageData()
  // 將各卡片的顯示分成兩種狀況 > 顯示最多三張卡片(三張卡片的情況手機平板顯示兩張，電腦版三張) or 全部卡片
  const [showAllCard, setShowAllCard] = useState(false)

  const search = typeof window !== "undefined" ? window.location.search : null
  const searchQuery = new URLSearchParams(search).get("s") || ""
  const {
    LunrIndex: { store, index: lunrIndex },
    allContentfulAsset: { nodes: assets },
  } = data

  const url =
    typeof window !== "undefined"
      ? `${window.location.pathname}${window.location.search}`
      : null

  const allAssets = assets.reduce((prev, { id, gatsbyImageData, title }) => {
    prev[id] = {
      gatsbyImageData,
      title,
    }
    return prev
  }, [])

  lunr.multiLanguage("en", "zh")
  const index = lunr.Index.load(lunrIndex)

  // 儲存所有搜尋結果
  const [results, setResults] = useState(null)

  // 將搜尋結果做分類後各卡片的結果
  const [postCards, setPostCards] = useState(null)
  const [courseCards, setCourseCards] = useState(null)
  const [teacherCards, setTeacherCards] = useState(null)
  const [onlineCourseCards, setOnlineCourseCards] = useState(null)

  // 用於蒐集篩選按鈕旁的結果數量
  const [count, setCount] = useState({
    all: null,
    course: null,
    teacher: null,
    onlineCourse: null,
    post: null,
  })

  const keywords = searchQuery
    .trim() // 移除關鍵字前空白字元
    .toLowerCase() // 統一小寫
    .split(/[\s+,.,+,&]/) // 分隔出關鍵字中的空格、.、+、&存為陣列

  useEffect(() => {
    const getSearchResult = () => {
      let andSearch = []
      keywords
        .filter(el => el.length > 1)
        .forEach((el, i) => {
          const keywordSearch = index
            .query(function (q) {
              q.term(el)
              q.term(el, {
                wildcard:
                  lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
              })
            })
            .map(({ ref }) => {
              return {
                id: ref,
                ...store[ref],
              }
            })
          andSearch =
            i > 0
              ? andSearch.filter(x =>
                  keywordSearch.some(el => el.slug === x.slug)
                )
              : keywordSearch
        })
      setResults(andSearch)
    }
    getSearchResult()
  }, [])

  // 用於取得最多三張卡片
  const getThreeCards = data => {
    const cards = []
    for (const [idx, item] of data.entries()) {
      cards.push(item)
      if (idx >= 2) break
    }

    return cards
  }

  const handleResult = (result, type) => {
    const data = results.filter(item => item.type === type)
    setCount(prev => ({ ...prev, [type]: data.length }))

    return showAllCard ? data : getThreeCards(data)
  }

  // 取得課程卡片結果
  useEffect(() => {
    const getCourseCards = () => {
      const courses = handleResult(results, "course")

      return courses.map((course, index) => {
        const {
          title,
          description,
          topic,
          level,
          category,
          slug,
          hours,
          numberOfPeople,
        } = course

        const isLastItem = index === courses.length - 1 && !showAllCard
        return (
          <CourseCard
            key={`courseCard-${index}`}
            title={title}
            description={description}
            topic={topic}
            level={level}
            category={category}
            slug={slug}
            hours={hours}
            numberOfPeople={numberOfPeople}
            hasButton={false}
            isLastItem={isLastItem}
          />
        )
      })
    }
    if (!isLoading) {
      setCourseCards(getCourseCards())
    }
  }, [results, showAllCard])

  // 取得講師卡片結果
  useEffect(() => {
    const getTeacherCards = () => {
      const teachers = handleResult(results, "teacher")

      return teachers.map((teacher, index) => {
        const { name, teacherType, description, slug, imageId } = teacher

        const asset = allAssets[imageId]
        const isLastItem = index === teachers.length - 1 && !showAllCard

        return (
          <TeacherCard
            key={`teacherCard-${index}`}
            name={name}
            type={teacherType}
            description={description}
            gatsbyImageData={asset.gatsbyImageData}
            slug={slug}
            isLastItem={isLastItem}
          />
        )
      })
    }
    if (!isLoading) {
      setTeacherCards(getTeacherCards())
    }
  }, [results, showAllCard])

  // 取得線上課卡片結果
  useEffect(() => {
    const getOnlineCourseCards = () => {
      const onlineCourses = handleResult(results, "onlineCourse")

      return onlineCourses.map((onlineCourse, index) => {
        const { title, description, imageId, topic, level, slug, minute } =
          onlineCourse

        const asset = allAssets[imageId]
        const isLastItem = index === onlineCourses.length - 1 && !showAllCard

        return (
          <OnlineCourseCard
            key={`onlineCourseCard-${index}`}
            title={title}
            description={description}
            topic={topic}
            level={level}
            slug={slug}
            minute={minute}
            image={asset.gatsbyImageData}
            isLastItem={isLastItem}
          />
        )
      })
    }
    if (!isLoading) {
      setOnlineCourseCards(getOnlineCourseCards())
    }
  }, [results, showAllCard])

  // 取得文章卡片結果
  useEffect(() => {
    const getPostCards = () => {
      const posts = handleResult(results, "post")

      return posts.map((post, index) => {
        const { slug, name, excerpt, date, topicName, topicSlug, imageId } =
          post
        const asset = allAssets[imageId]
        const data = {
          name,
          excerpt: { excerpt },
          topic: { name: topicName, slug: topicSlug },
          updatedAt: formatDate(new Date(date)),
          image: { gatsbyImageData: asset.gatsbyImageData },
          slug,
        }

        const isLastItem = index === posts.length - 1 && !showAllCard

        return (
          <PostCard
            key={`postCard-${index}`}
            data={data}
            isLastItem={isLastItem}
          />
        )
      })
    }
    if (!isLoading) {
      setPostCards(getPostCards())
    }
  }, [results, showAllCard])

  const isLoading = results === null

  const filterButtonInfo = [
    { name: "全部", value: "all" },
    { name: "課程", value: "course" },
    { name: "講師", value: "teacher" },
    { name: "線上課", value: "onlineCourse" },
    { name: "文章", value: "post" },
  ]

  // 儲存各篩選的結果(全部，課程，講師，線上課，文章)
  const filteredResults = {
    all: [
      { cards: courseCards, text: "課程", value: "course" },
      { cards: teacherCards, text: "講師", value: "teacher" },
      { cards: onlineCourseCards, text: "線上課", value: "onlineCourse" },
      { cards: postCards, text: "文章", value: "post" },
    ],
    course: [{ cards: courseCards, text: "課程", value: "course" }],
    teacher: [{ cards: teacherCards, text: "講師", value: "teacher" }],
    onlineCourse: [
      { cards: onlineCourseCards, text: "線上課", value: "onlineCourse" },
    ],
    post: [{ cards: postCards, text: "文章", value: "post" }],
  }

  // 用於追蹤篩選按鈕位置
  const [filterButtonName, setFilterButtonName] = useState("all")

  const filterButtons = filterButtonInfo.map(({ name, value }, index) => (
    <FilterButton
      name={name}
      value={value}
      count={count[value]}
      key={index}
      isCurrentButton={filterButtonName === value}
      setFilterButtonName={setFilterButtonName}
      setShowAllCard={setShowAllCard}
    />
  ))

  // 顯示各篩選結果

  const searchBlocks = filteredResults[filterButtonName]?.map(
    (result, index) => {
      const { cards, text, value } = result

      const buttonText = !showAllCard ? "所有結果" : "回到頂部"
      const handleFunc = () => {
        setShowAllCard(true)
        setFilterButtonName(value)
      }

      //  沒有資料 且 不是顯示全部 則 顯示沒有結果
      if (cards?.length === 0) {
        if (!showAllCard) {
          return null
        } else {
          return <NoResult key={`noResult-${index}`} />
        }
      }

      return (
        <div key={index}>
          <p className="text-lg !leading-loose font-bold text-neutral-900 sm:text-2xl">
            和 <span className="text-amber-400">{searchQuery}</span> 相關的
            {text}
          </p>
          <div className="grid gap-8 grid-cols-1 my-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-14">
            {cards}
          </div>
          <Navigation
            url={url}
            text={buttonText}
            handleFunc={!showAllCard ? handleFunc : undefined}
          />
        </div>
      )
    }
  )

  if (isLoading) {
    return (
      <Layout>
        <div className="wrapper mb-24 sm:mb-36">
          <div className="mt-12">
            <Code />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout showTitleTemplate={false}>
      <div className="wrapper mb-24 sm:mb-36">
        <p className="mt-12 mb-8 leading-normal text-3xl font-bold text-neutral-800 sm:mb-12 sm:text-5xl">
          <span className="text-amber-400">{searchQuery} </span>共有
          <span>{results?.length}</span>筆結果
        </p>
        <div
          className="mb-8 border-b border-b-neutral-300 pb-[7px] sm:mb-12"
          id="anchor_link"
        >
          <div className="flex gap-4 sm:gap-12">{filterButtons}</div>
        </div>
        {results.length ? (
          <div className="grid gap-20">{searchBlocks}</div>
        ) : (
          <NoResult />
        )}
      </div>
      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

FilterButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  count: PropTypes.any,
  isCurrentButton: PropTypes.bool,
  setFilterButtonName: PropTypes.func,
  setShowAllCard: PropTypes.func,
}

Navigation.propTypes = {
  url: PropTypes.string,
  setShowAllCard: PropTypes.func,
  setFilterButtonName: PropTypes.func,
  value: PropTypes.string,
  text: PropTypes.string,
  handleFunc: PropTypes.func,
}

Search.propTypes = { data: PropTypes.object }

export default Search

export const pageQuery = graphql`
  query {
    LunrIndex
    allContentfulAsset {
      nodes {
        id
        gatsbyImageData
        title
      }
    }
  }
`

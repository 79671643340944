import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Link from "../Link"
import ContinueReading from "./ContinueReading"
import CategoryAndUpdateTime from "./CategoryAndUpdateTime"

const PostCard = ({
  data,
  lgFlex = false,
  lgGrid = false,
  fixedWidth = false,
  isLast = false,
  isLastItem,
}) => {
  const {
    topic: { slug: topicSlug, name: topicName },
    image: { gatsbyImageData },
    excerpt: { excerpt },
    name,
    slug,
    updatedAt,
  } = data

  const postSlug = `/${topicSlug}/${slug}`

  return (
    <div
      className={`text-neutral-600 border border-neutral-400 border-b-4 border-b-amber-400 ${
        lgFlex ? "lg:flex lg:justify-center lg:space-y-0 lg:space-x-12" : ""
      } ${lgGrid ? "lg:grid grid-cols-2 lg:space-y-0 lg:gap-12" : ""} ${
        isLast ? "hidden sm:block lg:hidden" : ""
      } ${isLastItem ? "hidden lg:block" : ""}`}
    >
      <Link to={postSlug}>
        <div className="aspect-video">
          <GatsbyImage
            alt={name}
            image={gatsbyImageData}
            className={`w-full h-full ${fixedWidth ? "lg:w-96" : "w-full"}`}
          />
        </div>
      </Link>
      {/* Body */}
      <div className="p-3 sm:py-4 sm:px-6">
        <CategoryAndUpdateTime
          topicSlug={topicSlug}
          topicName={topicName}
          updatedAt={updatedAt}
        />
        <div className="group">
          <Link to={postSlug} className="space-y-4">
            <p
              className={`tracking-wide my-3 text-neutral-800 !leading-normal font-bold text-3xl line-clamp-3 group-hover:underline group-hover:underline-offset-4 ${
                lgGrid ? "sm:text-5xl" : ""
              }`}
            >
              {name}
            </p>
            <p className="!leading-normal text-neutral-600 line-clamp-3 sm:text-lg">
              {excerpt}
            </p>
          </Link>
        </div>
        <div className="mt-3">
          <ContinueReading postSlug={postSlug} />
        </div>
      </div>
    </div>
  )
}

PostCard.propTypes = {
  data: PropTypes.object.isRequired,
  lgFlex: PropTypes.bool,
  lgGrid: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  isLast: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

export default PostCard

import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"

const CategoryAndUpdateTime = ({ topicSlug, topicName, updatedAt }) => {
  return (
    <div className="flex space-x-6 text-lg text-amber-400 lg:justify-between">
      <Link
        to={`/${topicSlug}`}
        style="ease-in-out duration-300 font-bold text-primary-500 hover:text-amber-500"
      >
        {topicName}
      </Link>
      <p className="text-neutral-600">{updatedAt}</p>
    </div>
  )
}

CategoryAndUpdateTime.propTypes = {
  topicSlug: PropTypes.string,
  topicName: PropTypes.string,
  updatedAt: PropTypes.string,
}

export default CategoryAndUpdateTime
